import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "./App.css";
import { TbTriangleInvertedFilled } from "react-icons/tb";
import SideMenu from './components/SideMenu';
import Home from './components/Home';
import Config from './components/Config';
import Setting from './components/Setting';
import Graph from './components/Graph';
import Table from './components/Table';
import DomainsContext from './contexts/DomainsContext';
import DateRangePickerComponent from './components/DateRangePickerComponent';
import NoActivated from './components/NoActivated';
import { format } from 'date-fns';
import { WiDayCloudy } from 'react-icons/wi';

const App = ({ token }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [menuIndex, setMenuIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [domains, setDomains] = useState([]);
  const [step, setStep] = useState(1);
  const [btnValue, setBtnValue] = useState("další");
  const [domainChecked, setDomainChecked] = useState(false);
  const [checkedDomains, setCheckedDomains] = useState([]);
  const [domainsToVerifyDNS, setDomainsToVerifyDNS] = useState([]);
  const [domainsAfterVerification, setDomainsAfterVerification] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verifiedDomains, setVerifiedDomains] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphLabels, setGraphLabels] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currency, setCurrency] = useState("CZK");
  const [loading, setLoading] = useState(false);
  let [property_id, setProperty_id] = useState("");
  const [propertyIdArray, setPropertyIdArray] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const updateSlider = useRef(null);
  let [isActivated, setIsActivated] = useState(false)


  // State to track date range
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const chooseMenuItem = (index) => {
    setMenuIndex(index);
  };

  const getDomains = async () => {
    const domainsURL = "https://app.advisio.cz/dataplus/upgates/domain/";
    try {
      const response = await fetch(domainsURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: token }),
      });

      if (!response.ok) {
        navigate("/404/")
        throw new Error(`HTTP error! status: ${response.status}`);
      }



      const data = await response.json();
      Array.from(data).length <= 2 && navigate("/404/")
      const parsedData = JSON.parse(data);
      const uniqueDomainsMap = new Map();

      let isActivated = false

      parsedData.forEach((domain) => {
        if (domain.fields.status === "active") {
          const isPropertyId = propertyIdArray.some(
            (onePropertyId) =>
              onePropertyId.property_id === domain.fields.property_id
          );
          if (!property_id) {
            setProperty_id(domain.fields.property_id);
          }

          if (!isPropertyId) {
            setPropertyIdArray((prevState) => [
              ...prevState,
              { hostname: domain.fields.hostname, property_id: domain.fields.property_id },
            ]);
          }
          setIsActivated(true)
        }

        const cleanedHostname = domain.fields.hostname.split("/")[0];
        if (!uniqueDomainsMap.has(cleanedHostname)) {
          uniqueDomainsMap.set(cleanedHostname, { ...domain, id: domain.pk });
        }
      });

      setDomains(Array.from(uniqueDomainsMap.values()));
    } catch (error) {
      console.error(`Vyskytla se chyba: ${error}`);
      navigate("/404/")
    }
  };

  const chooseStep = (stepNumber) => {
    if (stepNumber === 1) {
      getDomains();
      setDomainChecked(false);
      setCheckedDomains([]);
      setDomainsToVerifyDNS([]);
      setDomainsAfterVerification({});
      setIsVerified(false);
      setVerifiedDomains([]);
      setBtnValue("další");
    } else if (stepNumber === 2) {
      if (!domainChecked) {
        alert("Musíte vybrat alespoň jednu doménu.");
        return;
      }
      const checkboxes = document.querySelectorAll(
        '.domains-form input[type="checkbox"]'
      );
      const selectedDomains = Array.from(checkboxes)
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => ({
          name: checkbox.nextElementSibling.textContent,
          id: checkbox.id,
        }));
      setCheckedDomains(selectedDomains);

      const transformedDomains = selectedDomains.map((oneDomain) => {
        let splitDomain = oneDomain.name.split(".");
        if (splitDomain.length === 3) {
          splitDomain[0] = "apps";
        } else if (splitDomain.length === 2) {
          splitDomain.unshift("apps");
        }
        return { domain: splitDomain.join("."), id: oneDomain.id };
      });

      setDomainsToVerifyDNS(transformedDomains);
    } else if (stepNumber === 3) {
      getDomains();
    }
    setStep(stepNumber);
    const positions = ["0", "25rem", "50rem"];
    updateSlider.current(stepNumber);
  };

  useEffect(() => {
    switch (step) {
      case 1:
        setBtnValue("další");
        break;
      case 2:
        setBtnValue("ověřit");
        break;
      case 3:
        setBtnValue("odeslat");
        break;
      default:
        setBtnValue("další");
    }
  }, [step]);

  const fetchGraphData = async (numOfDays) => {
    setIsVisible(false);
    setLoading(true);
    let dateRange = getDate(numOfDays);

    await getTableData(dateRange.start, dateRange.end);
    await getGrafData(dateRange.start, dateRange.end);
    setLoading(false);
  };

  const getDate = (numOfDays) => {
    let dates = {};

    const endDate = new Date();
    endDate.setDate(endDate.getDate());

    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - numOfDays + 1);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const formattedEndDate = endDate
      .toLocaleDateString("cs-CZ", options)
      .split(".")
      .reverse()
      .join("-")
      .replace(/\s/g, "");
    const formattedStartDate = startDate
      .toLocaleDateString("cs-CZ", options)
      .split(".")
      .reverse()
      .join("-")
      .replace(/\s/g, "");

    // Přidání jednoho dne k startDate
    let startDateObj = new Date(formattedStartDate);
    startDateObj.setDate(startDateObj.getDate() + 1);
    const newFormattedStartDate = startDateObj.toISOString().split("T")[0];

    dates.start = newFormattedStartDate;
    dates.end = formattedEndDate;
    return dates;
  };

  const getGrafData = async (startDate, endDate) => {

    setIsVisible(false);
    // getDomains()
    try {
      const response = await fetch(
        "https://app.advisio.cz/dataplus/dataset/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            start_date: startDate,
            end_date: endDate,
            property_id: property_id,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        navigate("/404/")
        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      setIsVisible(true);
      if (data.reports[0].rows) {
        let rows = data.reports[0].rows;
        let graphDataTemp = [];
        rows.forEach((row) => {
          let date = row.dimensionValues[0].value;
          let value = Number(row.metricValues[3].value);

          let graphObj = {
            date,
            value,
          };

          let existingItem = graphDataTemp.find(
            (item) => item.date === graphObj.date
          );

          if (existingItem) {
            existingItem.value += graphObj.value;
          } else {
            graphDataTemp.push(graphObj);
          }
        });

        graphDataTemp.sort((a, b) => {
          let dateA = new Date(
            a.date.slice(0, 4),
            a.date.slice(4, 6) - 1,
            a.date.slice(6, 8)
          );
          let dateB = new Date(
            b.date.slice(0, 4),
            b.date.slice(4, 6) - 1,
            b.date.slice(6, 8)
          );
          return dateA - dateB;
        });

        setGraphLabels(
          graphDataTemp.map((item) => {
            const date = new Date(
              item.date.slice(0, 4),
              item.date.slice(4, 6) - 1,
              item.date.slice(6, 8)
            );
            return `${date.getDate()}.${date.getMonth() + 1}.`;
          })
        );

        setGraphData(graphDataTemp.map((item) => item.value));
        setIsVisible(true);
      }
    } catch (error) {
      console.error("Error:", error);
      navigate("/404/")
    }
  };

  const getTableData = async (startDate, endDate) => {
    try {
      const response = await fetch(
        "https://app.advisio.cz/dataplus/dataset/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            start_date: startDate,
            end_date: endDate,
            property_id: property_id,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        navigate("/404/")
        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      if (data.reports[0].rows) {
        let rows = data.reports[0].rows;
        setCurrency(data.reports[0].metadata.currencyCode);

        setTableData(
          rows.map((row, index) => ({
            sourceMedium: row.dimensionValues[1].value,
            activeUsers: row.metricValues[0].value,
            advertiserAdCost: row.metricValues[1].value,
            transactions: row.metricValues[2].value,
            purchaseRevenue: row.metricValues[3].value,
            conversionRate:
              row.metricValues[0].value != 0
                ? (row.metricValues[2].value / row.metricValues[0].value) * 100
                : 0,
            pno:
              row.metricValues[3].value != 0
                ? (row.metricValues[1].value / row.metricValues[3].value) * 100
                : 0,
          }))
        );
      }
    } catch (error) {
      console.error("Error:", error);
      navigate("/404/")
    }
  };

  const handleDateChange = async (startDate, endDate) => {
    setIsVisible(false);
    if (startDate && endDate) {
      setLoading(true);

      // Přidání kontroly pro stejný den
      const sameDay =
        startDate.toISOString().split("T")[0] ===
        endDate.toISOString().split("T")[0];

      // Použití formátu pro stejný den
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = sameDay
        ? formattedStartDate
        : format(endDate, "yyyy-MM-dd");

      await getTableData(formattedStartDate, formattedEndDate);
      await getGrafData(formattedStartDate, formattedEndDate);

      setLoading(false);
    }
  };

  useEffect(() => {
    getDomains();
  }, []);

  useEffect(() => {
    if (property_id) {
      fetchGraphData(8);
    }
  }, [property_id]);

  // Update date range to the last 7 days when property_id changes
  useEffect(() => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 6);

    setDateRange({
      start: sevenDaysAgo,
      end: today,
    });
  }, [property_id]);

  const showSelected = (e) => {
    const selectedOption = e.target.selectedOptions[0].textContent;
    console.log("select", selectedOption);
    const newPropertyId = selectedOption.split(":")[1].trim();

    setProperty_id(newPropertyId);

  };

  return (
    <DomainsContext.Provider
      value={{
        domains,
        setDomains,
        step,
        setStep,
        btnValue,
        setBtnValue,
        domainChecked,
        setDomainChecked,
        checkedDomains,
        setCheckedDomains,
        domainsToVerifyDNS,
        setDomainsToVerifyDNS,
        domainsAfterVerification,
        setDomainsAfterVerification,
        isVerified,
        setIsVerified,
        isLoading,
        setIsLoading,
        updateSlider,
        chooseStep,
        verifiedDomains,
        setVerifiedDomains,
        getDomains,
        loading,
        setLoading,
        handleDateChange,
      }}
    >
      <div className="menu-container">
        <SideMenu
          chooseMenuItem={chooseMenuItem}
          getDomains={getDomains}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          setStep={setStep}
        />
      </div>
      <div className="container">
        {menuIndex === 0 && <Home />}
        {(menuIndex === 1 && !isActivated) && <NoActivated />}
        {(menuIndex === 1 && isActivated) && (
          <>
            <div className="graph-table-container">
              <DateRangePickerComponent onDateChange={handleDateChange} dateRange={dateRange} />
              {isVisible && (
                <div className="select-container">
                  <select
                    name="property-id-select"
                    id="property-id-select"
                    className="property-id-select"
                    onChange={showSelected}
                    value={property_id} // Set the value of the select element to property_id
                  >
                    {propertyIdArray.map((onePropertyId) => (
                      <option
                        value={onePropertyId.property_id}
                        key={onePropertyId.property_id}
                      >
                        {onePropertyId.hostname}: {onePropertyId.property_id}
                      </option>
                    ))}
                  </select>
                  <div className="custom-icon">
                    {/* <TbTriangleInvertedFilled /> */}
                  </div>
                </div>
              )}
              {loading ? (
                <div className="bar-chart">
                  <svg viewBox="0 0 100 100" className="graph-loading">
                    <path
                      id="graph-loading-line"
                      d="M13,87 L40,50 L60,70 L85,15"
                      fill="none"
                      stroke="#0AB0A4"
                      strokeWidth="6"
                    />
                    <line
                      x1="10"
                      y1="90"
                      x2="90"
                      y2="90"
                      stroke="#e2e2e2"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                    <line
                      x1="10"
                      y1="90"
                      x2="10"
                      y2="10"
                      stroke="#e2e2e2"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              ) : (
                <>
                  <Graph
                    data={graphData}
                    labels={graphLabels}
                    currency={currency}
                  />
                  <Table
                    data={tableData}
                    currency={currency}
                    chooseMenuItem={chooseMenuItem}
                    setActiveIndex={setActiveIndex}
                  />
                </>
              )}
            </div>
          </>
        )}
        {menuIndex === 2 && <Config />}
        {menuIndex === 3 && <Setting />}
      </div>
    </DomainsContext.Provider>
  );
};

export default App;
