import React from 'react';
import { ReactSVG } from 'react-svg';
import "./Home.css";
import { useBaseImageUrl } from '../contexts/BaseImageUrlContext';

const Home = () => {
    const baseImageURL = useBaseImageUrl();

    return (
        <div className="home-container">
            <h1>Úspěšný e-shop potřebuje přesná data</h1>
            <p>DataPlus přináší nejnovější technologii server-side tagging, díky které změříte až 99 % svých dat a získáte informace o úspěšnosti jednotlivých reklamních kanálů. Nepřijdete tak o zákazníky ani o tržby z online kampaní.</p>
            <p>Systém vyvinuli datoví analytici agentury Advisio a jedná se o originální řešení, které klientům už teď pomáhá s růstem tržeb. Spolehlivá podpora je k dispozici denně od 9 do 15 a mluví česky, slovensky, anglicky i polsky.</p>
            <p>S DataPlus nemusíte sledovat legislativní změny týkající se měření dat. O všechno se stará Advisio a systém podle potřeby aktualizuje.</p>
            <div className="logo-container">
                <a href="https://www.advisio.cz" target="_blank" rel="noopener">
                    <img src={`${baseImageURL}/svg/advisio-logo.svg`} alt="advisio logo" className='advisio-logo' />
                </a>
                <a href="https://www.dataplus.cz" target="_blank" rel="noopener">
                    <img src={`${baseImageURL}/svg/logo.svg`} alt="dataplus logo" className='dp-logo' />
                </a>
            </div>
        </div>
    );
};

export default Home;
