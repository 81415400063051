import React, { useRef, useContext, useEffect } from 'react';
import "./StepBar.css";
import DomainsContext from '../contexts/DomainsContext';

const StepBar = ({ chooseStep, getDomains }) => {
  const { step, btnValue, updateSlider, loading } = useContext(DomainsContext);
  const sliderRef = useRef(null);

  useEffect(() => {
    updateSlider.current = (stepNumber) => {
      const positions = ["0", "33.3%", "66.6%"];
      sliderRef.current.style.left = positions[stepNumber - 1];
    };
  }, [updateSlider]);

  const handleStepClick = (stepNumber) => {
    if (loading) {
      return; // Zablokuje přechod mezi kroky během načítání
    }
    if (step === 3 && stepNumber === 2) {
      return; // Zablokuje přechod na krok 2, pokud jsme již na kroku 3
    }
    if (stepNumber === 1) {
      getDomains(); // Načíst domény při kliknutí na první krok
    }
    if (stepNumber === 3 && btnValue !== "odeslat") {
      return; // Zablokuje přechod na krok 3, pokud btnValue není "odeslat"
    }
    chooseStep(stepNumber);
  };

  return (
    <div className="step-bar">
      <ul className="step-list">
        <li className="step-item" onClick={() => handleStepClick(1)}>
          <p>1</p>
        </li>
        <li className="step-item" onClick={() => handleStepClick(2)}>
          <p>2</p>
        </li>
        <li className="step-item" onClick={() => handleStepClick(3)}>
          <p>3</p>
        </li>
      </ul>
      <div className="slider" ref={sliderRef}></div>
    </div>
  );
};

export default StepBar;
