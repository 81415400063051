import React from 'react';
import "./ThirdConfigStep.css";
import { GrStatusInfo } from "react-icons/gr";

const ThirdConfigStep = () => {
  return (
    <div className="third-config-step-container">
      <h2>Nastavení DataPlus proběhlo v pořádku. Děkujeme za důvěru!</h2>
      <div className="info-wrapper">
        <GrStatusInfo className='info-logo' />
        <p>Jako poslední je potřeba propojit vytvořené GA4 s Google Ads. Ve videu níže naleznete podrobný návod. Poté dejte DataPlus dva dny čas na práci, získaná data pak uvidíte v sekci Graf.</p>
      </div>
      <div className="video-container">
        <iframe 
          width="672" 
          height="378" 
          src="https://www.youtube-nocookie.com/embed/oC9hg8wVw1g?si=h91PGfyMW7CLPNFo" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ThirdConfigStep;


