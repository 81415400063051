import React, { useState, useEffect, useRef, useContext } from 'react';
import "./SideMenu.css";
import { FaHome } from "react-icons/fa";
import { FaChartLine, FaScrewdriverWrench, FaGears } from "react-icons/fa6";
import { useBaseImageUrl } from '../contexts/BaseImageUrlContext';
import DomainsContext from '../contexts/DomainsContext';

const SideMenu = ({ chooseMenuItem, getDomains, activeIndex, setActiveIndex, setStep }) => {
  const menuRef = useRef(null);
  const [menuSize, setMenuSize] = useState(false);
  const timerRef = useRef(null);
  const baseImageURL = useBaseImageUrl();
  const { chooseStep } = useContext(DomainsContext)

  const icons = {
    home: <FaHome className="icon" />,
    chart: <FaChartLine className="icon" />,
    config: <FaScrewdriverWrench className="icon" />,
    setting: <FaGears className="icon" />,
    phone: <img src={`${baseImageURL}/svg/ico-phone-2.svg`} alt="Phone Icon" className="icon" />,
  };

  const handleMouseEnter = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setMenuSize(true);
    }, 50);
  };

  const handleMouseLeave = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setMenuSize(false);
    }, 50);
  };

  useEffect(() => {
    chooseMenuItem(activeIndex);
  }, []); // Pouze při inicializaci

  const handleMenuClick = (index, callback) => {
    setActiveIndex(index);
    chooseMenuItem(index);

    if (index === 2) {
      setStep(1)
      setTimeout(() => {
        chooseStep(1)
      }, 10);
    }

    if (callback) callback();
  };

  return (
    <aside
      className="menu-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ width: menuSize ? "200px" : "70px" }}
      ref={menuRef}
    >
      <section className="logo-box">
        {menuSize ? (
          <a href="https://dataplus.advisio.cz/?utm_source=addon&utm_medium=upgates" target='_blank'>
            <img src={`${baseImageURL}/svg/logo.svg`} alt="big dataplus logo" />
          </a>
        ) : (
          <a href="https://dataplus.advisio.cz/?utm_source=addon&utm_medium=upgates" target='_blank' className='small-logo-a'>
            <img src={`${baseImageURL}/svg/dp.svg`} alt="small dataplus logo" className='small-logo' />
          </a>
        )}
      </section>
      <nav className="menu-items">
        <ul className="menu-list">
          <li className={`home menu-item ${activeIndex === 0 ? 'active' : ''}`} onClick={() => handleMenuClick(0)}>
            {icons.home}
            <span className={`menu-text ${menuSize ? "show" : "hide"}`}>Úvod</span>
          </li>
          <li className={`chart menu-item ${activeIndex === 1 ? 'active' : ''}`} onClick={() => handleMenuClick(1)}>
            {icons.chart}
            <span className={`menu-text ${menuSize ? "show" : "hide"}`}>Graf</span>
          </li>
          <li className={`config menu-item ${activeIndex === 2 ? 'active' : ''}`} onClick={() => handleMenuClick(2, getDomains)}>
            {icons.config}
            <span className={`menu-text ${menuSize ? "show" : "hide"}`}>Konfigurace</span>
          </li>
          <li className={`setting menu-item ${activeIndex === 3 ? 'active' : ''}`} onClick={() => handleMenuClick(3)}>
            {icons.setting}
            <span className={`menu-text ${menuSize ? "show" : "hide"}`}>Nastavení</span>
          </li>
        </ul>
      </nav>
      <section className="help">
        {menuSize ? (
          <div>
            <p>Technická&nbsp;podpora<br /><a id="tel" href="tel:+420730670687">+420&nbsp;730&nbsp;670&nbsp;687</a></p>
            <p>(Po-Pá&nbsp;9:00-15:00)</p>
          </div>
        ) : (
          <div className="help-icon">
            {icons.phone}
          </div>
        )}
      </section>
    </aside>
  );
};

export default SideMenu;
