import React, { useContext, useEffect, useState } from 'react';
import "./SecondConfigStep.css";
import checkGreen from "../img/svg/check-green.svg";
import xMark from "../img/svg/xmark-solid.svg";
import DomainsContext from '../contexts/DomainsContext';
import { PiWarningDiamond } from "react-icons/pi";
import { GrStatusInfo } from "react-icons/gr";
import { PiWarning } from "react-icons/pi";


const SecondConfigStep = () => {
  const {
    domainsToVerifyDNS,
    domainsAfterVerification,
    setBtnValue,
    isVerified,
    setIsVerified,
    isLoading
  } = useContext(DomainsContext);

  const [verifiedDomainsArray, setVerifiedDomainsArray] = useState([]);
  const [infoText, setInfoText] = useState(
    <div className='info-wrapper'>
      <GrStatusInfo className='info-logo' />
      <p>
        Pro nastavení DataPlus je potřeba u těchto domén vytvořit DNS A záznam a AAAA záznam.
        Pro více informací o nastavení DNS u různých poskytovatelů navštivte&nbsp;
        <a href='https://www.advisio.cz/blog/navod-na-upravu-dns/?utm_source=addon&utm_medium=upgates' target='_blank' className='advisio-link'>
          Advisio – Blog
        </a>
      </p>
    </div>
  );

  useEffect(() => {
    if (isLoading) {
      setVerifiedDomainsArray([]);
      setInfoText(null); // Clear info text during loading
      return;
    }

    if (Object.keys(domainsAfterVerification).length > 0) {
      const verifiedDomains = domainsToVerifyDNS.map(domain => ({
        ...domain,
        verificationStatus: domainsAfterVerification[domain.domain]
      }));
      setVerifiedDomainsArray(verifiedDomains);

      const allVerified = verifiedDomains.every(domain => domain.verificationStatus === 1);
      if (allVerified) {
        setIsVerified(true);
        setInfoText(
          <div className='approved-wrapper'>
            <GrStatusInfo className='info-logo' />
            <p>
              Ověření DNS záznamů proběhlo úspěšně. Nyní můžete nastavení <i>Odeslat</i>.
            </p>
          </div>
        );
        setBtnValue("odeslat");
      } else {
        setIsVerified(false);
        const failedDomains = verifiedDomains.filter(domain => domain.verificationStatus !== 1).map(domain => domain.domain);
        setInfoText(
          <div className='warning-wrapper'>
            <PiWarning className='warning-logo' />
            <p>
              Ověření DNS záznamů se nepodařilo pro následující domény:<br />{failedDomains.join(', ')}<br /><br />
              Vraťte se na první krok a vyberte ty domény, u kterých máte DNS nastaveno správně.
              V případě potřeby můžete kontaktovat podporu na tel. čísle <a id="tel" href="tel:+420730670687">+420&nbsp;730&nbsp;670&nbsp;687</a>.
            </p>
          </div>
        );
        setBtnValue("zpět");
      }
    } else {
      setVerifiedDomainsArray(domainsToVerifyDNS);
      setIsVerified(false);
      setInfoText(
        <div className='info-wrapper'>
          <GrStatusInfo className='info-logo' />
          <p>
            Pro nastavení DataPlus je potřeba u těchto domén vytvořit DNS A záznam a AAAA záznam.
            Pro více informací o nastavení DNS u různých poskytovatelů navštivte&nbsp;
            <a href='https://www.advisio.cz/blog/navod-na-upravu-dns/?utm_source=addon&utm_medium=upgates' target='_blank' className='advisio-link'>
              Advisio&nbsp;–&nbsp;Blog
            </a>
          </p>
        </div>
      );
    }
  }, [isLoading, domainsAfterVerification, setBtnValue, setIsVerified, domainsToVerifyDNS]);

  return (
    <div className='second-config-step-container'>
      <div className="heading-box">
        <h2>Kontrola domén</h2>
      </div>
      <div className="second-config-step-info-box">
        {isLoading ? (
          <div className="bar-chart">
            <svg viewBox="0 0 100 100" className="graph-loading">
              <path id="graph-loading-line" d="M13,87 L40,50 L60,70 L85,15" fill="none" stroke="#0AB0A4" strokeWidth="6" />
              <line x1="10" y1="90" x2="90" y2="90" stroke="#e2e2e2" strokeWidth="3" strokeLinecap="round" />
              <line x1="10" y1="90" x2="10" y2="10" stroke="#e2e2e2" strokeWidth="3" strokeLinecap="round" />
            </svg>
          </div>
        ) : (
          <p className="dns-info">{infoText}</p>
        )}
      </div>
      <div className="second-config-domain-box">
        {isLoading ? (
          <div className="bar-chart">
            <svg viewBox="0 0 100 100" className="graph-loading">
              <path id="graph-loading-line" d="M13,87 L40,50 L60,70 L85,15" fill="none" stroke="#0AB0A4" strokeWidth="6" />
              <line x1="10" y1="90" x2="90" y2="90" stroke="#e2e2e2" strokeWidth="3" strokeLinecap="round" />
              <line x1="10" y1="90" x2="10" y2="10" stroke="#e2e2e2" strokeWidth="3" strokeLinecap="round" />
            </svg>
          </div>
        ) : (
          verifiedDomainsArray.length > 0 ?
            verifiedDomainsArray.map((oneDomain, index) => (
              <div key={index} className='second-config-domain-item'>
                <p className='second-config-domain-text' id={oneDomain.id}>{oneDomain.domain}</p>
                {'verificationStatus' in oneDomain && (
                  <img
                    src={oneDomain.verificationStatus === 1 ? checkGreen : xMark}
                    alt={oneDomain.verificationStatus === 1 ? "Verified" : "Not Verified"}
                    className='verification-icon'
                  />
                )}
              </div>
            )) :
            domainsToVerifyDNS.map((oneDomain, index) => (
              <p key={index} id={oneDomain.id} className='second-config-domain-text'>{oneDomain.domain}</p>
            ))
        )}
      </div>
    </div>
  );
}

export default SecondConfigStep;
