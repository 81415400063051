import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "./ConfigButton.css";
import DomainsContext from '../contexts/DomainsContext';

const ConfigButton = () => {
  const { 
    btnValue, 
    chooseStep, 
    domainsToVerifyDNS, 
    setDomainsAfterVerification,
    setIsLoading,
    step,
    setBtnValue,
    verifiedDomains, setVerifiedDomains,
    loading, setLoading // Přidáno
  } = useContext(DomainsContext);

  const navigate = useNavigate()
  const location = useLocation()

  const verificationDnsURL = "https://app.advisio.cz/dataplus/check_dns_domain/";
  const allowDomainURL = "https://app.advisio.cz/dataplus/allow_domain/";

  const sendForCheck = async () => {
    setIsLoading(true); // Načítání začíná
    setLoading(true); // Přidáno
    try {
      const response = await fetch(verificationDnsURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(domainsToVerifyDNS.map(domain => domain.domain)) // Odesílání pouze domén
      });
      const data = await response.json();
      setDomainsAfterVerification(data);

      const allVerified = Object.values(data).every(value => value === 1);
      if (allVerified) {
        setBtnValue("odeslat");
      } else {
        setBtnValue("zpět");
      }

      const verifiedDomainsList = domainsToVerifyDNS.filter(domain => data[domain.domain] === 1).map(domain => domain.domain);
      setVerifiedDomains(prev => [...prev, ...verifiedDomainsList]);
    } catch (error) {
      console.error(`Vyskytla se chyba: ${error}`);
      navigate("/404/")
    }
    setIsLoading(false); // Načítání končí
    setLoading(false); // Přidáno
  }

  const sendVerifiedDomains = async () => {
    const domainsForSend = JSON.stringify(verifiedDomains);
    setIsLoading(true); // Načítání začíná
    setLoading(true); // Přidáno
    try {
      const response = await fetch(allowDomainURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: domainsForSend
      });
      await response.text();
      // Po úspěšném odeslání:
      chooseStep(3); // Přechod na třetí krok
    } catch (error) {
      console.error(`Vyskytla se chyba: ${error}`);
      navigate("/404/")
    }
    setIsLoading(false); // Načítání končí
    setLoading(false); // Přidáno
  }

  const handleClick = async () => {
    if (btnValue === "ověřit") {
      await sendForCheck();
    } else if (btnValue === "zpět") {
      chooseStep(1);
    } else if (btnValue === "odeslat" && step === 2) {
      await sendVerifiedDomains();
    } else {
      chooseStep(step + 1);
    }
  };

  return (
    <div id="next-btn-box">
      <input
        type="button"
        value={loading ? "Čekejte..." : btnValue}
        className={`config-btn ${loading ? 'config-btn-loading' : ''}`}
        onClick={handleClick}
        disabled={loading}
      />
    </div>
  );
}

export default ConfigButton;
