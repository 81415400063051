import React, { createContext, useContext } from 'react';

const BaseImageUrlContext = createContext();

export const BaseImageUrlProvider = ({ children }) => {
    const baseImageURL = "https://db.advisio.cz/dataplus/img/";

    return (
        <BaseImageUrlContext.Provider value={baseImageURL}>
            {children}
        </BaseImageUrlContext.Provider>
    );
};

export const useBaseImageUrl = () => {
    return useContext(BaseImageUrlContext);
};
