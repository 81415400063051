import React from 'react'

const NoActivated = () => {
  return (
    <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
        <h1>Zatím jste nenakonfigurovali žádnou doménu</h1>
        <p>Přejděte do levého menu a zvolte "Konfigurace"</p>
    </div>
  )
}

export default NoActivated