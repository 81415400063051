import React, { useContext, useEffect } from 'react';
import "./Config.css";
import StepBar from './StepBar';
import FirstConfigStep from './FirstConfigStep';
import SecondConfigStep from './SecondConfigStep';
import ThirdConfigStep from './ThirdConfigStep';
import ConfigButton from './ConfigButton';
import DomainsContext from '../contexts/DomainsContext';

const Config = () => {
  const { 
    step, setStep, 
    btnValue, setBtnValue, 
    domainChecked, setDomainChecked, 
    checkedDomains, setCheckedDomains, 
    domainsToVerifyDNS, setDomainsToVerifyDNS, 
    domainsAfterVerification, setDomainsAfterVerification, 
    isVerified, setIsVerified, 
    isLoading, setIsLoading, 
    updateSlider,
    chooseStep,
    getDomains,
    loading
  } = useContext(DomainsContext);

  useEffect(() => {
    switch (step) {
      case 1:
        setBtnValue("další");
        break;
      case 2:
        setBtnValue("ověřit");
        break;
      case 3:
        setBtnValue("odeslat");
        break;
      default:
        setBtnValue("další");
    }
  }, [step, setBtnValue]);

  useEffect(() => {
    if (step === 2) {
      setDomainsAfterVerification({});
      setIsVerified(false);
      setIsLoading(false);
    }
  }, [step, setDomainsAfterVerification, setIsVerified, setIsLoading]);

  return (
    <div className='config-container'>
      <StepBar
        chooseStep={chooseStep}
        getDomains={getDomains} 
      />
      {step === 1 && (
        <>
          <FirstConfigStep domainsChecked={setDomainChecked} />
          <ConfigButton
            btnValue={btnValue}
            chooseStep={chooseStep}
            domainChecked={domainChecked}
            domainsToVerifyDNS={domainsToVerifyDNS} />
        </>
      )}
      {step === 2 && (
        <>
          <SecondConfigStep
            checkDomains={checkedDomains}
            domainsToVerifyDNS={domainsToVerifyDNS}
            domainsAfterVerification={domainsAfterVerification}
            setBtnValue={setBtnValue}
            isVerified={isVerified}
            setIsVerified={setIsVerified} />
          <ConfigButton
            btnValue={btnValue}
            chooseStep={chooseStep}
            domainChecked={domainChecked}
            domainsToVerifyDNS={domainsToVerifyDNS}
            setDomainsAfterVerification={setDomainsAfterVerification} />
        </>
      )}
      {step === 3 && <ThirdConfigStep />}
    </div>
  );
}

export default Config;

