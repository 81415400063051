import React, { useEffect, useRef } from 'react';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';
import './Table.css';

const Table = ({ data, currency, chooseMenuItem, setActiveIndex }) => {
  const tableRef = useRef(null);

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case 'CZK':
        return 'Kč';
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'GBP':
        return '£';
      case 'JPY':
        return '¥';
      case 'PLN':
        return 'zł';
      case 'HUF':
        return 'Ft';
      default:
        return currency;
    }
  };

  const aggregateData = (data) => {
    const aggregatedData = data.reduce((acc, row) => {
      if (!acc[row.sourceMedium]) {
        acc[row.sourceMedium] = {
          sourceMedium: row.sourceMedium,
          activeUsers: 0,
          advertiserAdCost: 0,
          transactions: 0,
          purchaseRevenue: 0
        };
      }
      acc[row.sourceMedium].activeUsers += Number(row.activeUsers);
      acc[row.sourceMedium].advertiserAdCost += Number(row.advertiserAdCost);
      acc[row.sourceMedium].transactions += Number(row.transactions);
      acc[row.sourceMedium].purchaseRevenue += Number(row.purchaseRevenue);
      return acc;
    }, {});

    return Object.values(aggregatedData).map(row => ({
      ...row,
      conversionRate: row.activeUsers !== 0 ? (row.transactions / row.activeUsers) : 0,
      pno: row.purchaseRevenue !== 0 ? (row.advertiserAdCost / row.purchaseRevenue) : 0,
    }));
  };

  const aggregatedData = aggregateData(data);

  const currencyFormatter = (cell) => {
    const value = parseFloat(cell.getValue());
    const currencySymbol = getCurrencySymbol(currency);
    return value.toLocaleString('cs-CZ') + ' ' + currencySymbol;
  };

  const percentageFormatter = (cell) => {
    const value = parseFloat(cell.getValue());
    return value.toLocaleString('cs-CZ', { style: 'percent', minimumFractionDigits: 2 });
  };

  const numberFormatter = (cell) => {
    const value = parseInt(cell.getValue(), 10);
    return value.toLocaleString('cs-CZ');
  };

  useEffect(() => {
    const table = new Tabulator(tableRef.current, {
      data: aggregatedData,
      layout: "fitData",
      height: "40rem",
      initialSort: [
        { column: "purchaseRevenue", dir: "desc" }
      ],
      columns: [
        { title: "Zdroj / Medium", field: "sourceMedium", width: 150, headerWordWrap: true },
        { title: "Aktivní uživatelé", field: "activeUsers", hozAlign: "right", headerWordWrap: true, formatter: numberFormatter, bottomCalc: "sum" },
        { title: "Konverzní poměr", field: "conversionRate", hozAlign: "right", headerWordWrap: true, formatter: percentageFormatter, bottomCalc: (values, data) => {
            const totalTransactions = data.reduce((sum, row) => sum + row.transactions, 0);
            const totalActiveUsers = data.reduce((sum, row) => sum + row.activeUsers, 0);
            return totalActiveUsers !== 0 ? (totalTransactions / totalActiveUsers) : 0;
          }, bottomCalcFormatter: percentageFormatter },
        {
          title: "Cena", field: "advertiserAdCost", hozAlign: "right", headerWordWrap: true, formatter: currencyFormatter, bottomCalc: "sum", bottomCalcFormatter: currencyFormatter
        },
        { title: "Konverze", field: "transactions", hozAlign: "right", headerWordWrap: true, formatter: numberFormatter, bottomCalc: "sum" },
        {
          title: "Hodnota konverze",
          field: "purchaseRevenue",
          hozAlign: "right",
          headerWordWrap: true,
          formatter: (cell) => {
            const value = parseFloat(cell.getValue());
            const cellElement = cell.getElement();
            if (cell.getRow().getData().sourceMedium === 'google / cpc' && value == 0) {
      
              cellElement.classList.add("clickable-cell");
            } else {
              cellElement.style.cursor = 'default'; // Set default cursor for all other cells
            }
            return currencyFormatter(cell);
          },
          bottomCalc: "sum",
          bottomCalcFormatter: currencyFormatter,
          sorter: "number",
          cellClick: (e, cell) => {
            const row = cell.getRow().getData();
            if (row.sourceMedium === 'google / cpc' && row.purchaseRevenue == 0) {
              chooseMenuItem(3)
              setActiveIndex(3)
            }
          },
        },
        { title: "PNO", field: "pno", hozAlign: "right", headerWordWrap: true, formatter: percentageFormatter, bottomCalc: (values, data) => {
            const totalCost = data.reduce((sum, row) => sum + row.advertiserAdCost, 0);
            const totalRevenue = data.reduce((sum, row) => sum + row.purchaseRevenue, 0);
            return totalRevenue !== 0 ? totalCost / totalRevenue : 0;
          }, bottomCalcFormatter: percentageFormatter }
      ],
      rowFormatter: (row) => {
        const cells = row.getCells();
        cells.forEach(cell => {
          cell.getElement().style.cursor = 'default'; // Set default cursor for all cells
        });
      }
    });

    return () => {
      table.destroy();
    };
  }, [aggregatedData, currency]);

  return (
    <div className="table-container">
      <div ref={tableRef} id="example-table"></div>
    </div>
  );
};

export default Table;
