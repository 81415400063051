import React from 'react';
import './Setting.css';
import { useBaseImageUrl } from '../contexts/BaseImageUrlContext';

const Setting = () => {
    const baseImageURL = useBaseImageUrl();

    return (
        <div className="setting-container">
            <h1>Jak nastavit propojení s Google Ads</h1>
            <p className="setting-info">
                Abyste mohli data získaná díky DataPlus využívat v reklamních kampaních, je potřeba <strong>propojit Google Analytics 4 s Google Ads.</strong> Jak na to?
            </p>
            <div className="video-container">
                <iframe
                    width="672"
                    height="378"
                    src="https://www.youtube-nocookie.com/embed/oC9hg8wVw1g?si=h91PGfyMW7CLPNFo"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            <ul>
                <li>
                    <p>Otevřete GA4, které chcete propojit. Vlevo dole klikněte na <i>Administrátor</i>.</p>
                    <img src={`${baseImageURL}tutorial/1.png`} alt="ga4-setting" className='ga4-img' />
                    <img src={`${baseImageURL}tutorial/2.png`} alt="ga4-admin" className='ga4-img' />
                </li>
                <li>
                    <p>V menu najděte sekci <i>Propojení služeb</i> a poté vyberte <i>Propojení Google Ads</i>.</p>
                    <img src={`${baseImageURL}tutorial/3.png`} alt="ga4-connect-google-ads" className='ga4-img' />
                </li>
                <li>
                    <p>Klikněte na tlačítko <i>Propojit</i> vpravo nahoře.</p>
                    <img src={`${baseImageURL}tutorial/4.png`} alt="ga4-connect-google-ads-button" className='ga4-img' />
                </li>
                <li>
                    <p>Vyberte Google Ads účet, který chcete propojit. V dalším nastavení nic neměňte a dejte<i> Odeslat</i>.</p>
                    <img src={`${baseImageURL}tutorial/5.png`} alt="ga4-connect-google-ads-choose-account" className='ga4-img' />
                    <img src={`${baseImageURL}tutorial/6.png`} alt="ga4-connect-google-ads-choose-account-steps" className='ga4-img' />
                    <img src={`${baseImageURL}tutorial/7.png`} alt="ga4-connect-google-ads-choose-account-step-1" className='ga4-img' />
                    <img src={`${baseImageURL}tutorial/8.png`} alt="ga4-connect-google-ads-choose-account-step-2" className='ga4-img' />
                    <img src={`${baseImageURL}tutorial/9.png`} alt="ga4-connect-google-ads-choose-account-submit" className='ga4-img' />
                    <img src={`${baseImageURL}tutorial/10.png`} alt="ga4-connect-google-ads-success-message" className='ga4-img' />
                </li>
            </ul>
            <p className='setting-info'>Pokud se vám nezobrazuje Váš Google Ads účet, zkontrolujte, že máte v GA4 i Google Ads dostatečná oprávnění.</p>
        </div>
    );
};

export default Setting;
