import React, { useContext } from 'react';
import "./FirstConfigStep.css";
import DomainsContext from '../contexts/DomainsContext';
import { GrStatusInfo } from "react-icons/gr";
import { IoCheckmarkDoneOutline } from "react-icons/io5";

const FirstConfigStep = ({ domainsChecked }) => {
  const { domains, verifiedDomains } = useContext(DomainsContext);

  const handleCheckboxChange = () => {
    const checkboxes = document.querySelectorAll('.domains-form input[type="checkbox"]');
    const anyChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
    domainsChecked(anyChecked);
  };

  const handleClick = (isDisabled, tooltipText) => (event) => {
    if (isDisabled) {
      event.preventDefault();
      alert(tooltipText);
    }
  };

  return (
    <>
      <div className='first-config-step-container'>
        <div className="heading-box">
          <h2>Vaše domény</h2>
        </div>
        <div className="first-config-step-info-box info-wrapper">
          <GrStatusInfo className='info-logo' />
          <p className="first-config-step-info">
            Vyberte domény, u kterých chcete nastavit DataPlus.
            Na další krok se dostanete jen v případě, že je vybrána aspoň jedna doména.
            Pokud je některá z domén označena zeleně, tak to znamená, že byla již aktivována.
          </p>
        </div>
        <form className='domains-form'>
          {domains.map((domain, index) => {
            const cleanedHostname = domain.fields.hostname.split('/')[0];
            const isDisabled = verifiedDomains.includes(cleanedHostname) || domain.fields.status === 'active';
            const tooltipText = "Tato doména již byla aktivována";
            return (
              <div className='domain-box' key={domain.id}>
                <input
                  type="checkbox"
                  name={cleanedHostname}
                  id={`${domain.id}`}
                  onChange={handleCheckboxChange}
                  onClick={handleClick(isDisabled, tooltipText)}
                  disabled={isDisabled}
                  className={domain.fields.status === 'active' ? 'disabled-chckbox' : ''}
                  title={isDisabled ? tooltipText : ''}
                />
                <label 
                  htmlFor={`${domain.id}`} 
                  className={`domain-text ${isDisabled ? 'disabled' : ''}`}
                  onClick={handleClick(isDisabled, tooltipText)}
                >
                  {cleanedHostname}
                </label>
              </div>
            );
          })}
        </form>
      </div>
    </>
  );
}

export default FirstConfigStep;
