import React from 'react';
import ReactDOM from 'react-dom/client';
import { BaseImageUrlProvider } from './contexts/BaseImageUrlContext';
import './index.css';
import App from './App';
import NotFound from './components/NotFound';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Function to ensure URL ends with a slash
const EnsureSlash = ({ children }) => {
  const location = useLocation();

  if (!location.pathname.endsWith('/')) {
    return <Navigate to={`${location.pathname}/`} replace />;
  }

  return children;
};

// Extract the token from the URL pathname
const tokenFromPath = window.location.pathname.split('/').filter(Boolean).pop();

root.render(
  <BaseImageUrlProvider>
    <Router>
      <EnsureSlash>
        <Routes>
          <Route path="*" element={<App token={tokenFromPath} />} />
          <Route path="/404/" element={<NotFound />} />
        </Routes>
      </EnsureSlash>
    </Router>
  </BaseImageUrlProvider>
);
