import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import './Graph.css';

const Graph = ({ data, labels, currency }) => {
  const chartRef = useRef(null);
  const myChartRef = useRef(null);

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case 'CZK':
        return 'Kč';
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'GBP':
        return '£';
      case 'JPY':
        return '¥';
      case 'PLN':
        return 'zł';
      case 'HUF':
        return 'Ft';
      default:
        return currency;
    }
  };

  useEffect(() => {
    if (myChartRef.current) {
      myChartRef.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    const currencySymbol = getCurrencySymbol(currency);

    myChartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Celkové tržby',
          data: data,
          backgroundColor: ["#FBA23C"],
          borderColor: ["#0AB0A4"],
          borderWidth: 5,
          borderDash: [5, 1],
          tension: 0.3,
          fill: false,
          hoverBackgroundColor: "0AB0A4",
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: "#0AB0A4",
          pointStyle: 'rectRounded',
          pointBorderWidth: 2,
          pointBorderColor: "#FBA23C"
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: false,
            ticks: {
              callback: function (value) {
                return value.toLocaleString('cs-CZ') + ' ' + currencySymbol;
              }
            }
          }
        },
        
        plugins: {
          title: {
            display: true,
            text: "Celkové tržby",
            font: {
              size: 20,
            },
            padding: {
              bottom: 30
            }
          },
          legend: {
            display: false,            
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.dataset.label || '';
                const value = context.raw;
                return `${label}: ${value.toLocaleString('cs-CZ')} ${currencySymbol}`;
              }
            }
          }
        }
      }
    });
  }, [data, labels, currency]);

  return (
    <div className="graph-container">
      <canvas ref={chartRef} id="myChart"></canvas>
    </div>
  );
};

export default Graph;