import React, { useState, useEffect, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';
import { cs } from 'date-fns/locale';
import './DateRangePickerComponent.css'; // new CSS file for date picker
import { defaultStaticRanges, defaultInputRanges } from './customRanges'; // import your custom ranges
import { BsCalendar2Week } from "react-icons/bs";
import { TbTriangleInvertedFilled } from "react-icons/tb";

const DateRangePickerComponent = ({ onDateChange, dateRange }) => {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 6);

  const [internalDateRange, setInternalDateRange] = useState([
    {
      startDate: dateRange.start || sevenDaysAgo,
      endDate: dateRange.end || today,
      key: 'selection'
    }
  ]);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const pickerRef = useRef(null);

  // Custom locale with two-letter day abbreviations and full month names
  const customCsLocale = {
    ...cs,
    localize: {
      ...cs.localize,
      day: (n) => {
        const shortDays = ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'];
        return shortDays[n];
      },
      month: (n) => {
        const fullMonths = [
          'leden', 'únor', 'březen', 'duben', 'květen', 'červen',
          'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'
        ];
        return fullMonths[n];
      }
    },
  };

  const handleDateChange = () => {
    const { startDate, endDate } = internalDateRange[0];
    if (startDate && endDate) {
      onDateChange(startDate, endDate);
      setShowDatePicker(false);
    }
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker]);

  // Update internal date range when the external date range changes
  useEffect(() => {
    setInternalDateRange([
      {
        startDate: dateRange.start || sevenDaysAgo,
        endDate: dateRange.end || today,
        key: 'selection'
      }
    ]);
  }, [dateRange]);

  const formattedStartDate = format(internalDateRange[0].startDate, 'dd.MM.yyyy', { locale: customCsLocale });
  const formattedEndDate = format(internalDateRange[0].endDate, 'dd.MM.yyyy', { locale: customCsLocale });

  return (
    <div className="date-inputs">
      <div onClick={() => setShowDatePicker(true)} className="date-display">
        <BsCalendar2Week className='calendar' />
        {formattedStartDate} — {formattedEndDate}
        <TbTriangleInvertedFilled className='triangle' />
      </div>
      {showDatePicker && (
        <div className="modal-overlay" onClick={() => setShowDatePicker(false)}>
          <div ref={pickerRef} className="date-picker-modal" onClick={(e) => e.stopPropagation()}>
            <DateRangePicker
              locale={customCsLocale}
              ranges={internalDateRange}
              onChange={(item) => setInternalDateRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              rangeColors={["#3d91ff"]}
              staticRanges={defaultStaticRanges} // use custom static ranges
              inputRanges={defaultInputRanges} // use custom input ranges
            />
            <div className="fetch-btn-container">
              <button onClick={handleDateChange} className='fetch-btn'>
                Načíst data
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePickerComponent;
