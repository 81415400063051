import React from 'react';

import "./NotFound.css"

const NotFound = () => {
  return (
    <div className='not-found-container'>
      <h1>Stránka nebyla nalezena</h1>
      <p>Omlouváme se, ale požadovaná stránka nebyla nalezena.</p>
      <p>Pokud potřebujete pomoc, obraťte se prosím na technickou podporu.</p>
      <div className='tech-support'>
        <p>Technická&nbsp;podpora<br />
          <a id="tel" href="tel:+420730670687">+420&nbsp;730&nbsp;670&nbsp;687</a>
        </p>
        <p>(Po-Pá&nbsp;9:00-15:00)</p>
      </div>
    </div>
  );
}

export default NotFound;
